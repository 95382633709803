<template>
  <div class="check-out-success">
    <div class="check-out-success__content">
      <h1>{{ $t('check_out.content.title') }}</h1>

      <ProfileDetail />
      <LanguageSelect absolutePosition />

      <!-- <h2>{{ $t('check_out.content.return_card_title') }}</h2>

      <p>
        {{ $t('check_out.content.return_card_info') }}
      </p> -->
    </div>
    <div class="check-out-success__actions">
      <ButtonInput @click="logEvent('onCheckOutSuccessCheckOutNextClicked') & $router.push({name: 'checkout'})" v-if="canCheckOutMore">
        {{ $t('check_out.action.check_out_next') }}
      </ButtonInput>
      <ButtonInput @click="logEvent('onCheckOutSuccessLogoutClicked') & clearReservationStoreAndRedirect()">
        {{ $t('action.button.finish') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  UPDATE_RESERVATION,
  GET_ROOMS,
} from '@/store/reservation';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import LanguageSelect from '@/components/LanguageSelect/index';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import ProfileDetail from '@/components/ProfileDetail';
import { CHECKOUT_BY_RESERVATION_PMS } from '@/constants/pmsIntegration';
import { logEvent } from '@/helpers/analytics';

export default {
  name: 'CheckOutSuccess',
  components: {
    ButtonInput,
    LanguageSelect,
    ProfileDetail,
  },
  data() {
    return {
      roomId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters({
      rooms: GET_ROOMS,
      property: GET_PROPERTY_DETAIL,
    }),
    checkOutByRoom() {
      return CHECKOUT_BY_RESERVATION_PMS.indexOf(this.property.integration) < 0;
    },
    canCheckOutMore() {
      if (!this.checkOutByRoom) {
        return false;
      }
      let result = false;
      _.each(this.rooms, (room) => {
        result |= room.id !== this.roomId && !room.checked_out;
      });
      return result;
    },
  },
  methods: {
    logEvent,
    clearReservationStoreAndRedirect() {
      return this.$store.dispatch(UPDATE_RESERVATION, {})
        .then(() => {
          this.$router.push({name: 'home'});
        })
        .catch(() => {
          this.$router.push({name: 'home'});
        });
    },
  },
};
</script>

<style lang="scss">
  .check-out-success {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;

    h1 {
      font-size: 3rem;
    }

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-top: .5rem;
    }

    &__content {
      margin-bottom: 2rem;
    }

    &__actions {
      display: flex;
      justify-content: center;

      .button {
        flex: 0 50%;
        margin: 0 1rem;
      }
    }
  }
</style>